import ArchiveIcon from '@material-ui/icons/Archive';
import React, { CSSProperties, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import BookingsView from '../components/BookingsView';
import { Badge, Box, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import Meta from '../components/Meta';
import BookingsProvider from '../providers/BookingsProvider';
import ActingAs from '../contexts/ActingAs';
import { useBookingListFilterContext } from '../providers/BookingListFilterProvider';
import { INITIAL_DATERANGE_FILTER, LAST_3_MONTHS } from '../providers/filterActions';
import set from 'lodash/fp/set';
import flow from 'lodash/fp/flow';
import { useBookingListPaginationContext } from '../providers/BookingListPaginationProvider';
import BookingRequestsProvider from '../providers/BookingRequestsProvider';
import BookingRequestsView from '../components/bookingRequests/BookingRequestsView';
import AssessmentIcon from '@material-ui/icons/Assessment';
import InputIcon from '@material-ui/icons/Input';
import firebase from 'firebase';
import { useBookingRequestsFilterContext } from '../providers/BookingRequestsFilterProvider';
import FirestoreCollectionProvider from '../providers/FirestoreCollection';
import Tags from '../contexts/Tags';
import { TagCategory } from '../model/Tag';
import { BookingRequestStatusCode } from '../model/BookingRequest';
import QueryString from 'querystring';
import { useHistory } from 'react-router';
import useUser from '../hooks/useUser';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: theme.breakpoints.values.xl,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

const useTabStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: CSSProperties;
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, value, index, style, ...other } = props;

  return (
    <Box
      className={classes.root}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const BookingsPageContainer: React.FC = () => {
  const classes = useTabStyles();
  const actingAs = useContext(ActingAs)[0];
  const userRecord = useUser()[1];

  const [bookingsContextData, setBookingsContextData] = useBookingListFilterContext();
  const setFilters = useBookingRequestsFilterContext()[1];
  const [bookingPaginationContextData, setBookingPaginationContextData] = useBookingListPaginationContext();
  const [bookingRequestCountsPerCarrier, setBookingRequestCountsPerCarrier] = useState<number[]>(
    actingAs ? [0] : userRecord.carriers?.map(() => 0) || [0],
  );
  const [bookingRequestCountsPerCarrierOnHold, setBookingRequestCountsPerCarrierOnHold] = useState<number[]>(
    actingAs ? [0] : userRecord.carriers?.map(() => 0) || [0],
  );
  const [bookingRequestCount, setBookingRequestCount] = useState(
    bookingRequestCountsPerCarrier?.reduce((a, b) => a + (b || 0), 0),
  );

  const [bookingRequestCountOnHold, setBookingRequestCountOnHold] = useState(
    bookingRequestCountsPerCarrierOnHold?.reduce((a, b) => a + (b || 0), 0),
  );

  const selectedTab = bookingPaginationContextData.activeTab;

  const history = useHistory();

  const params = QueryString.parse(window.location.search.replace('?', ''));
  const tab = params.tab as string | undefined;

  const tabToIndex: any = !actingAs
    ? {
        'pending-payment': 1,
        archived: 2,
        requests: 3,
        'on-hold': 4,
        'archived-requests': 5,
      }
    : {
        history: 1,
        requests: 2,
        'archived-requests': 3,
      };

  const indexToTab: any = !actingAs
    ? {
        1: 'pending-payment',
        2: 'archived',
        3: 'requests',
        4: 'on-hold',
        5: 'archived-requests',
      }
    : {
        1: 'history',
        2: 'requests',
        3: 'archived-requests',
      };

  useEffect(() => {
    !actingAs &&
      userRecord.carriers &&
      userRecord.carriers.length > 0 &&
      userRecord.carriers?.forEach((carrier, index) => {
        firebase
          .database()
          .ref(`/booking-requests-count-per-carrier/${carrier.toUpperCase()}`)
          .on('value', a =>
            setBookingRequestCountsPerCarrier(prevState => {
              return prevState.map((v, i) => (i === index ? a.val() : v));
            }),
          );
        firebase
          .database()
          .ref(`/booking-requests-count-per-carrier-on-hold/${carrier.toUpperCase()}`)
          .on('value', a =>
            setBookingRequestCountsPerCarrierOnHold(prevState => {
              return prevState.map((v, i) => (i === index ? a.val() : v));
            }),
          );
      });
  }, [userRecord.carriers, actingAs]);

  useEffect(() => {
    setBookingRequestCount(
      bookingRequestCountsPerCarrier ? Array.from(bookingRequestCountsPerCarrier).reduce((a, b) => a + (b || 0), 0) : 0,
    );
  }, [bookingRequestCountsPerCarrier]);

  useEffect(() => {
    setBookingRequestCountOnHold(
      bookingRequestCountsPerCarrierOnHold
        ? Array.from(bookingRequestCountsPerCarrierOnHold).reduce((a, b) => a + (b || 0), 0)
        : 0,
    );
  }, [bookingRequestCountsPerCarrierOnHold]);

  // Remember scroll position
  // useEffect(() => {
  //   if (bookingPaginationContextData.scrollPosition && !isLoading) {
  //     window.scroll(0, bookingPaginationContextData.scrollPosition);
  //   }
  //
  //   return () => {
  //     // as it will be remounted a few times we do not want to store position if the scroll did not actually happen
  //     if (window.scrollY > 200 && setBookingPaginationContextData) {
  //       setBookingPaginationContextData(prevState => set('scrollPosition', window.scrollY)(prevState));
  //     }
  //   };
  // }, [isLoading, bookingPaginationContextData.scrollPosition]);

  useEffect(() => {
    if (bookingsContextData.activeTab !== bookingPaginationContextData.activeTab) {
      if (!actingAs) {
        handleTabChange(bookingPaginationContextData.activeTab);
      } else {
        handleCustomerTabChange(bookingPaginationContextData.activeTab);
      }
    }
  }, [bookingPaginationContextData.activeTab]);

  const setSelectedTab = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      if (setBookingPaginationContextData) {
        setBookingPaginationContextData(set('activeTab', newValue)(bookingPaginationContextData));
      }
    },
    [bookingPaginationContextData.activeTab],
  );

  useEffect(() => {
    const newValue = tab && tabToIndex[tab] ? tabToIndex[tab] : bookingPaginationContextData.activeTab;
    if (setBookingPaginationContextData) {
      setBookingPaginationContextData(set('activeTab', newValue)(bookingPaginationContextData));
      bookingPaginationContextData.activeTab
        ? history.push(`/bookings?tab=${indexToTab[bookingPaginationContextData.activeTab]}`)
        : history.push(`/bookings`);
    }
  }, []);

  const handleTabChange = useCallback(
    (newValue: number) => {
      const bookingsContextDataNew = () => {
        switch (newValue) {
          case 0:
            history.push('/bookings');
            return flow(
              set('archived', false),
              set('pendingPayment', false),
              set('dateRange', undefined),
            )(bookingsContextData);
          case 1:
            history.push('/bookings?tab=pending-payment');
            return flow(
              set('archived', false),
              set('pendingPayment', true),
              set('dateRange', undefined),
            )(bookingsContextData);
          case 2:
            history.push('/bookings?tab=archived');
            return flow(
              set('archived', true),
              set('pendingPayment', undefined),
              set('dateRange', bookingsContextData.dateRange || INITIAL_DATERANGE_FILTER),
            )(bookingsContextData);
          case 3:
            history.push('/bookings?tab=requests');
            setFilters &&
              setFilters(prevState =>
                flow(
                  set('hold', false),
                  set('maxStatusCode', BookingRequestStatusCode.IN_PROGRESS),
                  set('minStatusCode', undefined),
                )(prevState),
              );
            return bookingsContextData;
          case 4:
            history.push('/bookings?tab=on-hold');
            setFilters &&
              setFilters(prevState =>
                flow(
                  set('hold', true),
                  set('maxStatusCode', BookingRequestStatusCode.IN_PROGRESS),
                  set('minStatusCode', undefined),
                )(prevState),
              );
            return bookingsContextData;
          case 5:
            history.push('/bookings?tab=archived-requests');
            setFilters &&
              setFilters(prevState =>
                flow(
                  set('minStatusCode', BookingRequestStatusCode.CONFIRMED),
                  set('maxStatusCode', undefined),
                )(prevState),
              );
            return bookingsContextData;
          default:
            return bookingsContextData;
        }
      };

      if (setBookingsContextData) {
        setBookingsContextData(set('activeTab', newValue)(bookingsContextDataNew()));
      }
    },
    [setBookingsContextData],
  );

  const handleCustomerTabChange = useCallback(
    (newValue: number) => {
      const bookingsContextDataNew = () => {
        switch (newValue) {
          case 0:
            history.push('/bookings');
            return flow(
              set('archived', false),
              set('pendingPayment', undefined),
              set('dateRange', undefined),
            )(bookingsContextData);
          case 1:
            history.push('/bookings?tab=history');
            return flow(
              set('archived', true),
              set('pendingPayment', undefined),
              set('dateRange', bookingsContextData.dateRange || INITIAL_DATERANGE_FILTER),
            )(bookingsContextData);
          case 2:
            history.push('/bookings?tab=requests');
            setFilters &&
              setFilters(prevState =>
                flow(
                  set('hold', false),
                  set('clientFilter', actingAs?.company),
                  set('maxStatusCode', BookingRequestStatusCode.IN_PROGRESS),
                  set('minStatusCode', undefined),
                )(prevState),
              );
            return bookingsContextData;
          case 3:
            history.push('/bookings?tab=archived-requests');
            setFilters &&
              setFilters(prevState =>
                flow(
                  set('minStatusCode', BookingRequestStatusCode.CONFIRMED),
                  set('maxStatusCode', undefined),
                )(prevState),
              );
            return bookingsContextData;
          default:
            return bookingsContextData;
        }
      };

      if (setBookingsContextData) {
        setBookingsContextData(set('activeTab', newValue)(bookingsContextDataNew()));
      }
    },
    [setBookingsContextData],
  );

  useEffect(() => {
    if (actingAs) {
      // we are acting as a customer set a date range:
      setBookingsContextData &&
        setBookingsContextData(
          flow(
            set('archived', false),
            set('pendingPayment', false),
            set('dateRange', bookingsContextData.dateRange || LAST_3_MONTHS),
          )(bookingsContextData),
        );
    }
  }, [actingAs, setBookingsContextData]);

  return (
    <Fragment>
      <Meta title="Bookings" />
      {!actingAs ? (
        <Box className={classes.tabContainer}>
          <Tabs
            value={selectedTab}
            onChange={setSelectedTab}
            orientation="vertical"
            aria-label="Booking tabs"
            className={classes.tabs}
          >
            <Tab icon={<FileCopyIcon />} label="Active" {...a11yProps(0)} />
            <Tab icon={<PaymentIcon />} label="Pending Payment" {...a11yProps(1)} />
            <Tab icon={<ArchiveIcon />} label="Archived" {...a11yProps(2)} />
            {/*<Divider />*/}
            <Tab
              icon={
                <Badge badgeContent={bookingRequestCount} color="primary">
                  <AssessmentIcon />
                </Badge>
              }
              label="Requests"
              {...a11yProps(3)}
            />
            <Tab
              icon={
                <Badge badgeContent={bookingRequestCountOnHold} color="primary">
                  <InputIcon />
                </Badge>
              }
              label="On Hold"
              {...a11yProps(4)}
            />
            <Tab icon={<InputIcon />} label="Archived Requests" {...a11yProps(5)} />
          </Tabs>
          <FirestoreCollectionProvider
            name="tags"
            context={Tags}
            query={query => query.where('category', '==', TagCategory.BOOKING)}
          >
            <TabPanel value={selectedTab} index={0}>
              <BookingsView isAdmin={!actingAs} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <BookingsView isAdmin={!actingAs} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <BookingsView isAdmin={!actingAs} archived showDateRangeFilter />
            </TabPanel>
          </FirestoreCollectionProvider>
          <FirestoreCollectionProvider
            name="tags"
            context={Tags}
            query={query => query.where('category', '==', TagCategory.BOOKING_REQUEST)}
          >
            <TabPanel value={selectedTab} index={3}>
              <BookingRequestsView isAdmin={!actingAs} />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <BookingRequestsView isAdmin={!actingAs} />
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
              <BookingRequestsView isAdmin={!actingAs} />
            </TabPanel>
          </FirestoreCollectionProvider>
        </Box>
      ) : (
        <Box className={classes.tabContainer}>
          <div id="tabsBkgPage">
            <Tabs
              value={selectedTab}
              onChange={setSelectedTab}
              orientation="vertical"
              aria-label="Booking tabs"
              className={classes.tabs}
            >
              <Tab icon={<FileCopyIcon />} label="Active" {...a11yProps(0)} />
              <Tab icon={<ArchiveIcon />} label="History" {...a11yProps(1)} />
              <Tab icon={<AssessmentIcon />} label="Requests" {...a11yProps(2)} />
              <Tab icon={<InputIcon />} label="Archived Requests" {...a11yProps(3)} />
            </Tabs>
          </div>
          <TabPanel value={selectedTab} index={0}>
            <BookingsView isAdmin={!actingAs} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <BookingsView isAdmin={!actingAs} archived showDateRangeFilter />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <BookingRequestsView isAdmin={!actingAs} />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <BookingRequestsView isAdmin={!actingAs} />
          </TabPanel>
        </Box>
      )}
    </Fragment>
  );
};

const BookingsPage = () => {
  return (
    <BookingsProvider>
      <BookingRequestsProvider>
        <BookingsPageContainer />
      </BookingRequestsProvider>
    </BookingsProvider>
  );
};

export default BookingsPage;
