import React, { Fragment, useCallback, useContext } from 'react';
import { Box, Button, createStyles, IconButton, ListItem, makeStyles, Typography } from '@material-ui/core';
import NotificationItemView from './NotificationItemView';
import Notification from '../../model/Notification';
import CloseIcon from '@material-ui/icons/Close';
import firebase from '../../firebase';
import { GlobalContext } from '../../store/GlobalStore';
import { FirebaseActionType } from '../../model/FirebaseAction';
import useUser from '../../hooks/useUser';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(50),
      },
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(65),
      },
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(70),
      },
    },
  }),
);

const readAllNotifications = async (userEmail: string, userAlphacomId: string) =>
  firebase
    .firestore()
    .collection('functions-action')
    .doc()
    .set({
      date: new Date(),
      type: FirebaseActionType.MARK_ALL_NOTIFICATIONS_AS_READ,
      done: false,
      userEmail,
      userAlphacomId,
    });

const NotificationsView: React.FC<Props> = ({
  notifications,
  handleShow,
  filterByUnread,
  onFilterByUnread,
  onShowMore,
  numberToLoad,
}) => {
  const [, dispatch] = useContext(GlobalContext);
  const [, userRecord] = useUser();
  const markAllAsRead = useCallback(async () => {
    dispatch({ type: 'START_GLOBAL_LOADING' });
    if (!userRecord.emailAddress) return;
    readAllNotifications(userRecord.emailAddress, userRecord.alphacomId)
      .then(() => {
        handleShow();
        dispatch({ type: 'SHOW_SUCCESS_SNACKBAR', message: 'Success.' });
      })
      .catch(error =>
        dispatch({ type: 'SHOW_ERROR_SNACKBAR', message: `Error marking all notifications as read - ${error}` }),
      )
      .finally(() => dispatch({ type: 'STOP_GLOBAL_LOADING' }));
  }, [dispatch, userRecord, handleShow]);

  return (
    <Fragment>
      <Box
        px={4}
        py={1}
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={1}
        borderColor="grey.300"
      >
        <Typography variant="h4">Notifications</Typography>
        <IconButton aria-label="close-button-notification-center" onClick={handleShow}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box width="100%" flexDirection="column" justifyContent="center">
        {notifications && notifications.length === 0 && filterByUnread ? (
          <Box flex={1} display="flex">
            <Box display="flex" flexDirection="column" margin="auto" padding={2}>
              <Typography style={{ padding: 2 }}>You don't have any unread notifications.</Typography>
              <Button variant="contained" onClick={onFilterByUnread} style={{ margin: 'auto' }}>
                View all
              </Button>
            </Box>
          </Box>
        ) : (
          <Fragment>
            <Box px={4} flex={1} display="flex" justifyContent="space-between">
              <Button size="small" color="primary" variant="outlined" onClick={onFilterByUnread}>
                {filterByUnread ? 'View all' : 'Filter by unread'}
              </Button>
              <Button size="small" color="primary" variant="outlined" onClick={markAllAsRead}>
                Mark all as read
              </Button>
            </Box>
            {notifications?.map(notification => (
              <ListItem key={notification.id}>
                <NotificationItemView notification={notification} handleShowDrawer={handleShow} />
              </ListItem>
            ))}
            {notifications.length === numberToLoad && (
              <Box display="flex" justifyContent="center">
                <Button onClick={onShowMore}>Show More</Button>
              </Box>
            )}
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default NotificationsView;
interface Props {
  notifications: Notification[];
  handleShow: () => void;
  filterByUnread: boolean;
  onFilterByUnread: () => void;
  onShowMore: () => void;
  numberToLoad: number;
}
