import React, { Fragment } from 'react';
import { Box, Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { ActivityLogItem } from './ActivityModel';
import {
  ActivityChangeType,
  ActivityLogUserData,
  ActivityText,
  ChecklistItemValueDocumentStatusType,
} from './ChecklistItemModel';
import DateFormattedText from '../../DateFormattedText';
import { formatDateSafe } from '../../../utilities/formattingHelpers';
import { activityHasLink, getFullName, isPlatformActivity } from '../../../utilities/activityHelper';
import { BookingRequestLabels } from '../../../model/BookingRequest';
import isString from '../../../utilities/isString';
import { isNil } from 'lodash/fp';
import { TaskDescription } from '../../../model/Task';
import { ActivityLogAvatar } from './ActivityLogAvatar';
import getTermsForCarrier from '../../../utilities/getTermsForCarrier';

const createUsersRepresentation = (users: ActivityLogUserData[]) => {
  return users.map((user, index) => {
    return (
      <Fragment key={user.alphacomId}>
        {user.emailAddress ? (
          <Link href={`mailto:${user.emailAddress}`}>{`${user.firstName} ${user.lastName}`}</Link>
        ) : (
          user.firstName + ' ' + user.lastName
        )}
        {index === users.length - 1 ? '' : ', '}
      </Fragment>
    );
  });
};

const createEmailRepresentation = (paymentConfirmationEmails: string[]) => {
  return paymentConfirmationEmails.map((email, index) => {
    return (
      <Fragment key={index}>
        <Link href={`mailto:${email}`}>{email}</Link>
        {index === paymentConfirmationEmails.length - 1 ? '.' : ', '}
      </Fragment>
    );
  });
};

export const makeActivityRepresentation = (activity: ActivityLogItem) => {
  const makeStyledString = (activity: ActivityLogItem, index: number) =>
    activity.documents && index !== activity.documents?.length - 1 ? ', ' : ' ';
  const mapChangeTypeToText = (): ActivityText | undefined => {
    switch (activity.changeType) {
      case ActivityChangeType.CHECKED:
        return activity?.checklistItem?.checked ? ActivityText.CHECKED : ActivityText.UNCHECKED;
      case ActivityChangeType.ADD_FILE:
        return activity.documents!.length > 1 ? ActivityText.ADD_FILES : ActivityText.ADD_FILE;
      case ActivityChangeType.DELETE_FILE:
        return ActivityText.DELETE_FILE;
      case ActivityChangeType.STAGE_CHECKED:
        return activity.stage!.checked ? ActivityText.CHECKED : ActivityText.UNCHECKED;
      case ActivityChangeType.DOCUMENT_STATUS_CHANGED:
        return activity.documents?.[0]?.status?.type === ChecklistItemValueDocumentStatusType.DEFAULT
          ? ActivityText.DEFAULTED_FILE
          : activity.documents?.[0]?.status?.type === ChecklistItemValueDocumentStatusType.APPROVED
          ? ActivityText.APPROVED_FILE
          : ActivityText.REJECTED_FILE;
      case ActivityChangeType.DONE_BY_CUSTOMER:
        return ActivityText.DONE_BY_CUSTOMER;
      case ActivityChangeType.UNDO_COMPLETED_CUSTOMER:
        return ActivityText.UNDO_COMPLETED_CUSTOMER;
      case ActivityChangeType.SELECT_FOR_COMPARISON:
        return ActivityText.SELECT_FOR_COMPARISON;
      case ActivityChangeType.UNSELECT_FOR_COMPARISON:
        return ActivityText.UNSELECT_FOR_COMPARISON;
      case ActivityChangeType.MARK_AS_FINAL:
        return ActivityText.MARK_AS_FINAL;
      case ActivityChangeType.UNMARK_AS_FINAL:
        return ActivityText.UNMARK_AS_FINAL;
      case ActivityChangeType.POSTPONE_PAYMENT:
        return ActivityText.POSTPONE_PAYMENT;
      case ActivityChangeType.APPROVE_PAYMENT:
        return ActivityText.APPROVE_PAYMENT;
      case ActivityChangeType.REVERT_PAYMENT_APPROVAL:
        return ActivityText.REVERT_PAYMENT_APPROVAL;
      case ActivityChangeType.PUT_ON_HOLD_PAYMENT:
        return ActivityText.PUT_ON_HOLD_PAYMENT;
      case ActivityChangeType.REVERT_PUT_ON_HOLD_PAYMENT:
        return ActivityText.REVERT_PUT_ON_HOLD_PAYMENT;
      case ActivityChangeType.PUT_ON_HOLD_BOOKING_REQ:
        return ActivityText.PUT_ON_HOLD_BOOKING_REQ;
      case ActivityChangeType.REVERT_PUT_ON_HOLD_BOOKING_REQ:
        return ActivityText.REVERT_PUT_ON_HOLD_BOOKING_REQ;
      case ActivityChangeType.SET_WATCHING:
        return ActivityText.SET_WATCHING;
      case ActivityChangeType.UNSET_WATCHING:
        return ActivityText.UNSET_WATCHING;
      case ActivityChangeType.ASSIGNED_AGENT:
        return ActivityText.ASSIGNED_AGENT;
      case ActivityChangeType.ASSIGNED_CLIENT:
        return ActivityText.ASSIGNED_CLIENT;
      case ActivityChangeType.UNASSIGNED_AGENT:
        return ActivityText.UNASSIGNED_AGENT;
      case ActivityChangeType.UNASSIGNED_CLIENT:
        return ActivityText.UNASSIGNED_CLIENT;
      case ActivityChangeType.SET_WATCHERS:
        return ActivityText.SET_WATCHERS;
      case ActivityChangeType.CLEAR_PAYMENT:
        return ActivityText.CLEAR_PAYMENT;
      case ActivityChangeType.REVERT_CLEAR_PAYMENT:
        return ActivityText.REVERT_CLEAR_PAYMENT;
      case ActivityChangeType.MARK_SOMETHING_WRONG:
        return ActivityText.MARK_SOMETHING_WRONG;
      case ActivityChangeType.ARCHIVED:
        return ActivityText.ARCHIVED;
      case ActivityChangeType.UNARCHIVED:
        return ActivityText.UNARCHIVED;
      case ActivityChangeType.EDITED:
        return ActivityText.EDITED;
      case ActivityChangeType.ASSIGNED_ON_TASK:
        return ActivityText.ASSIGNED_ON_TASK;
      case ActivityChangeType.SENT_PAYMENT_CONFIRMATION_EMAIL:
        return ActivityText.SENT_PAYMENT_CONFIRMATION_EMAIL;
      case ActivityChangeType.BOOKING_CREATION:
        return ActivityText.BOOKING_CREATION;
    }
  };

  return (
    <Fragment>
      <Typography>
        {isPlatformActivity(activity.by) ? (
          `Platform`
        ) : (
          <Link href={`mailto:${activity.by.emailAddress}`}>{getFullName(activity)}</Link>
        )}
        {mapChangeTypeToText()}
        {activity.changeType === ActivityChangeType.BOOKING_CREATION && (
          <Link target="_blank" href={getTermsForCarrier(activity.carrierId)} color={'primary'}>
            Terms and Conditions
          </Link>
        )}
        {activity.stage && ` '${activity.stage?.label}' stage `}
        {(activity.changeType === ActivityChangeType.ASSIGNED_AGENT ||
          activity.changeType === ActivityChangeType.ASSIGNED_CLIENT) &&
        activity.addedUsers
          ? createUsersRepresentation(activity.addedUsers)
          : null}
        {(activity.changeType === ActivityChangeType.UNASSIGNED_AGENT ||
          activity.changeType === ActivityChangeType.UNASSIGNED_CLIENT) &&
        activity.addedUsers
          ? createUsersRepresentation(activity.addedUsers)
          : null}
        {activity.task && activity.addedUsers && (
          <>
            <Typography component="span">{createUsersRepresentation(activity.addedUsers)}</Typography>
            <Typography component="span">{' on the task '}</Typography>
            <Typography component="span" color="primary">
              {TaskDescription[activity.task.type] || '[no description]'}
            </Typography>
          </>
        )}
        {activity.changeType === ActivityChangeType.SET_WATCHERS ? (
          activity.addedUsers && activity.addedUsers.length > 0 ? (
            <Fragment>
              <Fragment>
                {'added to watchers '}
                {createUsersRepresentation(activity.addedUsers)}
              </Fragment>

              {activity.removedUsers && activity.removedUsers.length > 0 && (
                <Fragment>
                  {' and removed '}
                  {createUsersRepresentation(activity.removedUsers)}
                </Fragment>
              )}
            </Fragment>
          ) : (
            activity.removedUsers &&
            activity.removedUsers.length > 0 && (
              <Fragment>
                {'removed from watchers '}
                {createUsersRepresentation(activity.removedUsers)}
              </Fragment>
            )
          )
        ) : null}
        {activity.documents &&
          activity.documents.map((doc, index) => {
            return activityHasLink(activity) ? (
              <Fragment key={doc.url}>
                <Link href={doc.url} target="_blank">
                  {doc.name}
                </Link>
                {makeStyledString(activity, index)}
              </Fragment>
            ) : (
              `${doc.name} `
            );
          })}
        {(activity.changeType === ActivityChangeType.SELECT_FOR_COMPARISON ||
          activity.changeType === ActivityChangeType.UNSELECT_FOR_COMPARISON) &&
          ' for comparison'}
        {activity.changeType === ActivityChangeType.APPROVE_PAYMENT ||
        activity.changeType === ActivityChangeType.REVERT_PAYMENT_APPROVAL ||
        activity.changeType === ActivityChangeType.POSTPONE_PAYMENT ||
        activity.changeType === ActivityChangeType.PUT_ON_HOLD_PAYMENT ||
        activity.changeType === ActivityChangeType.REVERT_PUT_ON_HOLD_PAYMENT ||
        activity.changeType === ActivityChangeType.CLEAR_PAYMENT ||
        activity.changeType === ActivityChangeType.REVERT_CLEAR_PAYMENT ||
        activity.changeType === ActivityChangeType.MARK_SOMETHING_WRONG ? (
          <Fragment>
            {activity.paymentReference}
            {activity.changeType === ActivityChangeType.POSTPONE_PAYMENT &&
            activity.paymentActivityData?.dateBeforeChange &&
            activity.paymentActivityData.dateAfterChange
              ? ` from ${formatDateSafe(
                  activity.paymentActivityData.dateBeforeChange,
                  'd. MMMM yyyy',
                )} to ${formatDateSafe(activity.paymentActivityData.dateAfterChange, 'd. MMMM yyyy')}`
              : null}
          </Fragment>
        ) : activity.changeType === ActivityChangeType.SENT_PAYMENT_CONFIRMATION_EMAIL &&
          activity.paymentConfirmationEmails ? (
          createEmailRepresentation(activity.paymentConfirmationEmails)
        ) : (
          <Fragment>
            {activity.changeType !== ActivityChangeType.DONE_BY_CUSTOMER &&
            (activity.checklistItem || activity.isInternal || activity.isAccountingActivity)
              ? activity.changeType === ActivityChangeType.ADD_FILE
                ? ' into '
                : ' from '
              : null}

            {activity.checklistItem ? (
              <Fragment>
                <Link href={`#${activity.checklistItem.id}`}>{` ${activity.checklistItem.label}`}</Link> item
              </Fragment>
            ) : !activity.isAccountingActivity ? (
              activity.isInternal ? (
                'Internal storage'
              ) : null
            ) : (
              'Accounting'
            )}
          </Fragment>
        )}
      </Typography>
      {activity.changedFields && (
        <Fragment>
          {activity.changedFields.map((field, index) => {
            return (
              <Box display={'flex'} key={index}>
                <Typography color={'primary'}>{BookingRequestLabels[field.fieldName]}</Typography>
                <Typography style={{ paddingLeft: '.5em' }}>
                  {isString(field.oldVal) && isString(field.newVal) ? `(${field.oldVal} -> ${field.newVal})` : ''}
                  {isNil(field.oldVal) && isString(field.newVal) ? `( -> ${field.newVal})` : ''}
                  {isString(field.oldVal) && isNil(field.newVal) ? `(${field.oldVal} -> )` : ''}
                </Typography>
                {index !== activity.changedFields!.length - 1 && (
                  <Typography style={{ paddingRight: '.5em' }}>,</Typography>
                )}
              </Box>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
};

const Activity = ({ activity, ...other }: Props) => {
  return (
    <ListItem {...other}>
      <ListItemAvatar>
        <ActivityLogAvatar name={getFullName(activity)} />
      </ListItemAvatar>
      <ListItemText
        primary={makeActivityRepresentation(activity)}
        secondary={<DateFormattedText date={activity.at} />}
      />
    </ListItem>
  );
};

export default Activity;

interface Props {
  activity: ActivityLogItem;
}
