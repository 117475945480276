import {
  ActivityChangeType,
  ActivityLogUserData,
  ShortChecklistItem,
  ShortChecklistItemValueDocument,
  Stage,
} from './ChecklistItemModel';
import { MentionItem } from 'react-mentions';
import { flow } from 'lodash/fp';
import update from 'lodash/fp/update';
import invoke from 'lodash/fp/invoke';
import { normalizePaymentActivityData } from '../documentApproval/ComparisonDialogContent';
import Task from '../../../model/Task';

export interface ActivityLogItem {
  id?: string;
  comment?: string;
  documents?: ShortChecklistItemValueDocument[] | null;
  paymentConfirmationEmails?: string[];
  checklistItem?: ShortChecklistItem;
  paymentReference?: string;
  stage?: Stage;
  by: ActivityLogUserData | Platform;
  at: Date;
  type: ActivityType;
  isInternal: boolean;
  changeType?: ActivityChangeType;
  mentions?: MentionItem[];
  autoGenerated?: boolean;
  isAccountingActivity?: boolean;
  addedUsers?: ActivityLogUserData[];
  removedUsers?: ActivityLogUserData[];
  paymentActivityData?: PaymentActivityData;
  isPinned?: boolean;
  changedFields?: ChangedField[];
  path?: string;
  task?: Task;
  carrierId?: string;
}

export interface ChangedField {
  fieldName: string;
  oldVal?: any;
  newVal?: any;
}

export enum ActivityType {
  COMMENT,
  ACTIVITY,
  ACTIVITY_WITH_COMMENT,
}

export interface QuoteActivityModel {
  id?: string;
  comment: string;
  by: ActivityLogUserData;
  at: Date;
  type: ActivityType;
  isInternal: boolean;
  mentions?: MentionItem[];
}
export interface QuoteGroupActivityModel {
  id?: string;
  comment: string;
  by: ActivityLogUserData;
  at: Date;
  type: ActivityType;
  isInternal: boolean;
  mentions?: MentionItem[];
  groupId: string;
}

export interface PaymentActivityData {
  dateBeforeChange?: Date;
  dateAfterChange?: Date;
}

export type Platform = 'PLATFORM';

export const normalizeActivity = (activity: any) =>
  flow(
    update('at', invoke('toDate')),
    update('paymentActivityData', normalizePaymentActivityData),
  )(activity) as ActivityLogItem;
