import React, { useState } from 'react';
import ManualRouteShortView from './ManualRouteShortView';
import { Box, Button, Paper } from '@material-ui/core';
import { Currency } from '../../../../model/Payment';
import {
  ManualProviderRoute,
  ManualProviderRouteEntity,
  PricePerContainer,
  PriceRange,
  ProviderRoutesType,
} from '../../../../model/land-transport/providers/ProviderRoutes';
import theme from '../../../../theme';
import {
  addLandTransportExtensionGroupDefault,
  addLandTransportRoute,
  editLandTransportRoute,
} from '../../../../api/landTransportConfig';
import { set } from 'lodash/fp';
import useLandTransportRoutes from '../../../../hooks/useLandTransportRoutes';
import AddIcon from '@material-ui/icons/Add';
import EmptyStatePanel from '../../../EmptyStatePanel';
import ProviderEntity from '../../../../model/land-transport/providers/Provider';

type InitialManualProviderRoute = Omit<ManualProviderRoute, 'updatedAt' | 'priceRange' | 'pricePerContainer'>;

const defaultItem: InitialManualProviderRoute = {
  type: ProviderRoutesType.MANUAL,
  // price: { currency: Currency.CHF, value: 300 }, todo. Check this is not on Model
  transportMode: '',
  validity: null,
  description: '',
  destination: '',
  origin: '',
  currency: Currency.EUR,
  active: false,
};

interface Props {
  provider: ProviderEntity;
}

const ManualRoutesList: React.FC<Props> = ({ provider }) => {
  const [newRow, setNewRow] = useState(false);
  const routes = useLandTransportRoutes(provider.id, ProviderRoutesType.MANUAL);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {routes?.length !== 0 && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ alignSelf: 'flex-end', marginBottom: theme.spacing(2) }}
          disabled={newRow}
          onClick={() => setNewRow(true)}
        >
          Add route
        </Button>
      )}
      <Box display="flex" flexDirection="column" flex={1} style={{ gap: theme.spacing(2) }}>
        {newRow && (
          <ManualRouteShortView
            provider={provider}
            route={defaultItem as ManualProviderRouteEntity}
            isAddMode
            onCancel={() => setNewRow(false)}
            addItem={item =>
              addLandTransportRoute(
                provider.id,
                set('priceRange', getRangePricesByContainer(item.pricePerContainer, item.currency))(item),
              ).then(val => addLandTransportExtensionGroupDefault(provider.id, val.id))
            }
            editItem={(id, item) =>
              editLandTransportRoute(
                provider.id,
                id,
                set('priceRange', getRangePricesByContainer(item.pricePerContainer, item.currency))(item),
              )
            }
          />
        )}
        {routes?.length !== 0 ? (
          routes?.map(v => (
            <ManualRouteShortView
              provider={provider}
              route={v}
              key={v.id}
              addItem={item =>
                addLandTransportRoute(
                  provider.id,
                  set('priceRange', getRangePricesByContainer(item.pricePerContainer, item.currency))(item),
                ).then(val => addLandTransportExtensionGroupDefault(provider.id, val.id))
              }
              editItem={(id, item) =>
                editLandTransportRoute(
                  provider.id,
                  id,
                  set('priceRange', getRangePricesByContainer(item.pricePerContainer, item.currency))(item),
                )
              }
            />
          ))
        ) : (
          <Box mt={3} component={Paper}>
            <EmptyStatePanel
              title="No manual routes"
              subtitle="Click the button below to create the first manual route"
              actionLabel="Add route"
              actionIcon={<AddIcon />}
              action={() => setNewRow(true)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const getRangePricesByContainer = (priceByContainer: PricePerContainer, currency: Currency): PriceRange | null => {
  if (!priceByContainer) return null;
  return Object.values(priceByContainer).reduce(
    (previousValue, currentValue, index) => {
      if (index === 0)
        return { min: { value: currentValue.value, currency }, max: { value: currentValue.value, currency } };
      if (previousValue.max.value < currentValue.value) {
        return set('max.value', currentValue.value)(previousValue);
      } else if (previousValue.min.value > currentValue.value) {
        return set('min.value', currentValue.value)(previousValue);
      } else {
        return previousValue;
      }
    },
    { min: { value: Number.MAX_VALUE, currency }, max: { value: Number.MIN_VALUE, currency } },
  );
};

export default ManualRoutesList;
