import React, { Fragment, useContext } from 'react';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  BookingCategory,
  BookingLocType,
  BookingVersion,
  CargoDetail,
  CargoOverdimension,
  CtrTariff,
  CtrTariffDetail,
  EquipmentDetail,
  LocRefItem,
  Remark,
} from '../../model/Booking';
import ContainerType from '../../model/ContainerType';
import ContainerTypes from '../../contexts/ContainerTypes';
import { isImport, isLongVersion } from './BookingView';
import ImcoContainer from './ImcoContainer';
import { ReactComponent as ContainerIconSVG } from '../../assets/container.svg';
import { ReactComponent as PackageIconSVG } from '../../assets/package.svg';
import { ReactComponent as WeightIconSVG } from '../../assets/weight.svg';
import theme from '../../theme';
import invoke from 'lodash/fp/invoke';
import { DateFormats, formatDateSafe } from '../../utilities/formattingHelpers';
import { detectAndInsertLink } from '../../utilities/detectAndInsertLink';

interface Props {
  cargoDetail: CargoDetail[];
  version: BookingVersion;
  category: BookingCategory;
  tariffDetails: CtrTariffDetail[];
  remarks: Remark[];
}

interface TableRowProps {
  label: string;
  content: string;
}

interface ContainerDatesProps {
  equipment: EquipmentDetail;
  bookingCategory: BookingCategory;
}

interface ContainerDatesContentProps {
  firstDate: string | null;
  secondDate: string | null;
  firstLabel: string;
  secondLabel: string;
}

interface EquipmentProps {
  equipment: EquipmentDetail[];
  bookingCategory: BookingCategory;
}

interface AdditionalCargoProps {
  detail: CargoDetail;
}

interface CtrTariffProps {
  ctrTariffs: CtrTariff[] | null;
  tariffDetails: CtrTariffDetail[];
  detail: CargoDetail;
}

interface CtrTariffDetailProps {
  ctrTariffs: CtrTariff[] | null;
  tariffDetails: CtrTariffDetail[];
  type: string;
}

interface ContainerItemProps {
  detail: CargoDetail;
  index?: number;
  containerTypes: ContainerType[] | undefined;
  version: BookingVersion;
  category: BookingCategory;
  tariffDetails: CtrTariffDetail[];
  remarks: Remark[];
}

const mediaPrint = '@media print';
const useStyles = makeStyles(theme => ({
  tableCellLabel: {
    verticalAlign: 'top',
    paddingLeft: 0,
    border: 'none',
    fontWeight: 700,
    maxWidth: '8em',
  },
  tableRow: {
    verticalAlign: 'top',
    '@media not print': {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& td': {
          display: 'block',
          padding: theme.spacing(0),
        },
      },
    },
    [mediaPrint]: {
      '& td': {
        padding: theme.spacing(0),
      },
    },
  },
  tableCell: {
    verticalAlign: 'top',
    border: 'none',
  },
}));

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 'fit-content',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

/*
  This method is made because they changed representation of Pin number,
   in last version they had only one Pin number on ContainerDetail field,
   now they can have multiple pin numbers, and they moved pin number on
   equipment level, so because of that we have to see if this booking has new or old presentation of that field
   so we can display it properly
 */
const hasEquipmentPinNumber = (detail: CargoDetail) => {
  const hasPinNrPerEquipment = detail.Equipment.some(e => e.PINNr !== undefined);
  return hasPinNrPerEquipment ? (
    <TableRowData
      label={'Pin Number'}
      content={detail.Equipment.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.ContainerNumber + ' - ' + (currentValue.PINNr || '') + '<br>',
        '',
      )}
    />
  ) : detail.PINNr ? (
    <TableRowData label={'Pin Number'} content={detail.PINNr} />
  ) : null;
};

export const TableRowData: React.FC<TableRowProps> = ({ label, content }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCellLabel}>{label}</TableCell>
      <TableCell className={classes.tableCell} dangerouslySetInnerHTML={{ __html: content }} />
    </TableRow>
  );
};

export const ContainerDatesContent: React.FC<ContainerDatesContentProps> = ({
  firstDate,
  secondDate,
  firstLabel,
  secondLabel,
}) => {
  return (
    <Grid container>
      {firstDate ? (
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid container>
            <Grid item style={{ width: '75px' }}>
              {firstLabel}
            </Grid>
            <Grid item style={{ width: '80px', paddingRight: '0px' }}>
              {formatDateSafe(invoke('toDate')(firstDate), DateFormats.LONG)}
            </Grid>
          </Grid>
          {secondDate ? (
            <Grid container spacing={0}>
              <Grid item style={{ width: '75px', paddingRight: '0px' }}>
                {secondLabel}
              </Grid>
              <Grid item style={{ width: '80px' }}>
                {formatDateSafe(invoke('toDate')(secondDate), DateFormats.LONG)}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ) : secondDate ? (
        <Grid container spacing={1}>
          <Grid item>{secondLabel}</Grid>
          <Grid item>{formatDateSafe(invoke('toDate')(secondDate), DateFormats.LONG)}</Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const ContainerDates: React.FC<ContainerDatesProps> = ({ equipment, bookingCategory }) => {
  return isImport(bookingCategory) ? (
    <ContainerDatesContent
      firstDate={equipment.GateOutDate}
      firstLabel={'Gate Out:'}
      secondDate={equipment.DropOffDate}
      secondLabel={'Drop Off:'}
    />
  ) : (
    <ContainerDatesContent
      firstDate={equipment.PickUpDate}
      firstLabel={'Pick Up:'}
      secondDate={equipment.GateInDate}
      secondLabel={'Gate In:'}
    />
  );
};

export const EquipmentData: React.FC<EquipmentProps> = ({ equipment, bookingCategory }) => {
  const classes = useStyles();

  const containersToDisplay = equipment.filter(
    eqDetail => eqDetail.ContainerNumber && eqDetail.ContainerNumber !== 'NOT AVAILABLE',
  );

  return (
    <Fragment>
      {containersToDisplay.length > 0 && (
        <TableRow>
          <TableCell className={classes.tableCellLabel}>Containers</TableCell>
          <TableCell className={classes.tableCell}>
            {containersToDisplay.map(equipmentDetail => (
              <div key={`ctdet-${equipmentDetail.ContainerNumber}`}>
                {equipmentDetail.GateInDate ||
                equipmentDetail.PickUpDate ||
                equipmentDetail.DropOffDate ||
                equipmentDetail.GateOutDate ? (
                  <HtmlTooltip
                    title={<ContainerDates equipment={equipmentDetail} bookingCategory={bookingCategory} />}
                    placement={'right'}
                  >
                    <span>{equipmentDetail.ContainerNumber}</span>
                  </HtmlTooltip>
                ) : (
                  equipmentDetail.ContainerNumber
                )}
              </div>
            ))}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export const CtrTariffDetailType: React.FC<CtrTariffDetailProps> = ({ tariffDetails, ctrTariffs, type }) => {
  const classes = useStyles();
  const data =
    ctrTariffs && ctrTariffs[0] && tariffDetails && tariffDetails[0]
      ? tariffDetails.map(tariff =>
          tariff.DaysFree &&
          tariff.Txt &&
          ctrTariffs &&
          ctrTariffs.some(ctrTariff => ctrTariff.ID === tariff.ID && ctrTariff.Type === tariff.Type) ? (
            <Fragment key={`tariffId-${tariff.ID}`}>
              {tariff.DaysFree + ' ' + tariff.Txt}
              <br />
            </Fragment>
          ) : null,
        )
      : null;

  return ctrTariffs && ctrTariffs[0] && tariffDetails && tariffDetails[0] ? (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>{type}</TableCell>
      <TableCell className={classes.tableCell}>
        {data && !data.every(item => item === null) ? data : 'ON REQUEST'}
      </TableCell>
    </TableRow>
  ) : null;
};

export const CtrTariffDetails: React.FC<CtrTariffProps> = ({ tariffDetails, ctrTariffs, detail }) => {
  const demDetTariffs = tariffDetails.filter(tariffDetail => tariffDetail.Type === 'DEM/DET');
  const storageTariffs = tariffDetails.filter(tariffDetail => tariffDetail.Type === 'STORAGE');
  const pluginTariffs = tariffDetails.filter(tariffDetail => tariffDetail.Type === 'PLUGIN');

  return (
    <Fragment>
      {detail.DemDetTariff !== '0' && (
        <Fragment>
          {demDetTariffs && demDetTariffs[0] ? (
            <CtrTariffDetailType tariffDetails={demDetTariffs} ctrTariffs={ctrTariffs} type={'Dem./Det. tariff'} />
          ) : (
            <CtrTariffDetailType tariffDetails={demDetTariffs} ctrTariffs={null} type={'Dem./Det. tariff'} />
          )}
        </Fragment>
      )}
      {detail.StorageTariff !== '0' && (
        <Fragment>
          {storageTariffs && storageTariffs[0] ? (
            <CtrTariffDetailType tariffDetails={storageTariffs} ctrTariffs={ctrTariffs} type={'Storage tariff'} />
          ) : (
            <CtrTariffDetailType tariffDetails={storageTariffs} ctrTariffs={null} type={'Storage tariff'} />
          )}
        </Fragment>
      )}
      {detail.PluginTariff !== '0' && (
        <Fragment>
          {pluginTariffs && pluginTariffs[0] ? (
            <CtrTariffDetailType tariffDetails={pluginTariffs} ctrTariffs={ctrTariffs} type={'Plug-in tariff'} />
          ) : (
            <CtrTariffDetailType tariffDetails={pluginTariffs} ctrTariffs={null} type={'Plug-in tariff'} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export const AdditionalCargoData: React.FC<AdditionalCargoProps> = ({ detail }) => {
  const emptyReturnAddress = (detail.LocRefs && detail.LocRefs[0]
    ? detail.LocRefs.map(ref => (ref.LocType === BookingLocType.dropOff && ref.LocID !== '0' ? ref.LocDet : null))
    : null
  )?.join('');
  const destinationTerminal = (detail.LocRefs && detail.LocRefs[0]
    ? detail.LocRefs.map(ref => (ref.LocType === BookingLocType.gateOut ? ref.LocDet : null))
    : null
  )?.join('');

  return (
    <Fragment>
      {destinationTerminal ? <TableRowData label={'Destination Terminal'} content={destinationTerminal} /> : null}

      {hasEquipmentPinNumber(detail)}
      {emptyReturnAddress ? <TableRowData label={'Empty Return Address'} content={emptyReturnAddress} /> : null}

      {detail.Stock ? <TableRowData label={'Stock'} content={detail.Stock} /> : null}

      {detail.DropOffRef ? <TableRowData label={'Drop Off Reference'} content={detail.DropOffRef} /> : null}
    </Fragment>
  );
};

const ContainerItem: React.FC<ContainerItemProps> = ({
  detail,
  containerTypes,
  index,
  version,
  category,
  tariffDetails,
  remarks,
}) => {
  const cont = containerTypes?.find(type => type.id === detail.CtypID);
  const classes = useStyles();
  const arrivalItemRemark = remarks.find(r => r.RemarkType === 'ARRIVAL INFO REMARKS');
  return (
    <Fragment>
      <Typography variant="h5">{index ? `ITEM ${index + 1}` : 'ITEM 1'}</Typography>
      <Box marginTop="0em" marginBottom="2em">
        <Grid container spacing={2} style={{ paddingTop: '10px' }}>
          <Grid item md={5} xs={12}>
            <Table size="small" aria-label="a dense table">
              <colgroup>
                <col style={{ width: '35%', paddingRight: theme.spacing(0) }} />
                <col style={{ width: '65%' }} />
              </colgroup>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <SvgIcon component={ContainerIconSVG} viewBox="0 0 512 512" />
                  </TableCell>
                  <TableCell className={classes.tableCell}>{`${detail.CtrQuantity} x ${cont?.description ||
                    detail.CtypID}`}</TableCell>
                </TableRow>

                {detail.CommodityTXT ? (
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <SvgIcon component={PackageIconSVG} viewBox="0 0 512 512" />
                    </TableCell>
                    <TableCell className={classes.tableCell}>{detail.CommodityTXT}</TableCell>
                  </TableRow>
                ) : null}

                {detail.CtrWeight ? (
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <SvgIcon component={WeightIconSVG} viewBox="0 0 512 512" />
                    </TableCell>
                    <TableCell className={classes.tableCell}>{detail.CtrWeight}</TableCell>
                  </TableRow>
                ) : null}

                {detail.Temperature ? (
                  <TableRowData label={'Temperature'} content={detail.Temperature?.toUpperCase()} />
                ) : null}

                {detail.Dehumidification ? (
                  <TableRowData label={'Dehumidification'} content={detail.Dehumidification} />
                ) : null}

                {detail.Ventilation ? <TableRowData label={'Ventilation'} content={detail.Ventilation} /> : null}

                {detail.IMCO && detail.IMCOs && detail.IMCOs[0] ? <ImcoContainer IMCOs={detail.IMCOs} /> : null}

                {detail.Overdimension && detail.Overdimension === CargoOverdimension.Trigger && (
                  <OverdimensionComponent detail={detail} key={`${index}-Overdimension`} />
                )}

                {detail.Equipment && detail.Equipment[0] ? (
                  <EquipmentData equipment={detail.Equipment} bookingCategory={category} />
                ) : null}

                {isLongVersion(version) && tariffDetails && detail.Equipment && detail.Equipment[0] ? (
                  <CtrTariffDetails
                    key={`tariff-${index}`}
                    tariffDetails={tariffDetails}
                    ctrTariffs={detail.Equipment[0].CtrTariffs}
                    detail={detail}
                  />
                ) : null}
              </TableBody>
            </Table>
          </Grid>

          {isLongVersion(version) ? (
            <Grid item md={7} xs={12}>
              <Table size="small" aria-label="a dense table">
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '80%' }} />
                </colgroup>
                <TableBody>
                  {isImport(category) ? <AdditionalCargoData detail={detail} /> : null}

                  {detail.LocRefs.map((ref: LocRefItem, index: number) => {
                    if (ref.LocType === 'PICK UP' && ref.LocDate) {
                      return (
                        <Fragment key={`booking-loc-ref-${index}`}>
                          <TableRowData label={'Pick Up Reference'} content={ref.LocRef} />
                          <TableRowData label={'Pick Up Date'} content={ref.LocDate} />
                          <TableRowData label={'Pick Up Location'} content={ref.LocDet} />
                        </Fragment>
                      );
                    }

                    if (ref.LocType === 'DELIVERY') {
                      return (
                        <Fragment key={`booking-loc-type-${index}`}>
                          <TableRowData label={'Delivery Reference'} content={ref.LocRef} />
                          <TableRowData label={'Delivery Address'} content={ref.LocDet} />
                        </Fragment>
                      );
                    }
                  })}
                  {detail['VGM-PIN'] && (
                    <TableRowData label={'VGM Pin'} content={detail['VGM-PIN']} key={`booking-vgm-pin-type-${index}`} />
                  )}
                  {detail.CargoDetailRermarks && (
                    <TableRowData label={'Remarks'} content={detail.CargoDetailRermarks} />
                  )}
                  {arrivalItemRemark && (
                    <TableRowData
                      label={'Arrival Items Remark'}
                      content={detectAndInsertLink(arrivalItemRemark.RemarkTxt)}
                    />
                  )}
                </TableBody>
              </Table>
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <Box marginTop="2em" marginBottom="2em">
        <Divider />
      </Box>
    </Fragment>
  );
};

const ContainerDetails: React.FC<Props> = ({ cargoDetail, version, category, tariffDetails, remarks }) => {
  const containerTypes = useContext(ContainerTypes);

  return (
    <Grid id="equipmentDetailsBkg" item xs={12}>
      {cargoDetail &&
        cargoDetail.map((cargoDetailItem, index) => (
          <ContainerItem
            key={`cargo-detail-${index}`}
            index={index}
            detail={cargoDetailItem}
            containerTypes={containerTypes}
            version={version}
            category={category}
            tariffDetails={tariffDetails}
            remarks={remarks}
          />
        ))}
    </Grid>
  );
};

interface Overdimension {
  detail: CargoDetail;
}

export const OverdimensionComponent: React.FC<Overdimension> = ({ detail }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>Overdimension</TableCell>
      <Box display="flex" flexDirection="column">
        <TableCell className={classes.tableCell}>
          <Box display="flex" flexDirection="column">
            {detail.Overwidth ? <Typography>{`OW: ${detail.Overwidth}`}</Typography> : null}
            {detail.Overheight ? <Typography>{`OH: ${detail.Overheight}`}</Typography> : null}
            {detail.Overlength ? <Typography>{`OL: ${detail.Overlength}`}</Typography> : null}
          </Box>
        </TableCell>
      </Box>
    </TableRow>
  );
};

export default ContainerDetails;
