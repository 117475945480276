export enum ItemsOptions {
  DEPOT_OUT = 'DEPOT_OUT',
  SOC_CERTIFICATE = 'SOC_CERTIFICATE',
  INVOICED = 'INVOICED',
  IMO = 'IMO',
  IMO_REQUESTED = 'IMO_REQUESTED',
  IMO_APPROVED = 'IMO_APPROVED',
  FINAL_DGD_SHEET = 'FINAL_DGD_SHEET_AND_DELIVERY_DETAILS',
  INFORMED_PORT = 'INFORMED_PORT',
  OOG = 'OOG',
  OOG_APPROVED = 'OOG_APPROVED',
  OOG_REQUESTED = 'OOG_REQUESTED',
  LASHING_CERTIFICATE = 'LASHING_CERTIFICATE',
  GATE_IN_TERMINAL = 'GATE_IN_TERMINAL',
  BHT_NUMBER_ISSUANCE = 'BHT_NUMBER_ISSUANCE',
  VGM_SUBMISSION = 'VGM_SUBMISSION',
  SHIPPING_INSTRUCTIONS = 'SHIPPING_INSTRUCTIONS',
  B_L = 'B_L',
  BL_DRAFT_SENT = 'BL_DRAFT_SENT ',
  BL_DRAFT_APPROVED = 'BL_DRAFT_APPROVED ',
  FINAL_BL_COPY = 'FINAL_BL_COPY ',
  SHIPPED_ON_BOARD = 'SHIPPED_ON_BOARD',
  OTHER = 'OTHER',
  MANIFESTED = 'MANIFESTED',
  BILL_OF_LADING = 'BILL_OF_LADING',
  // import specific items
  BILL_OF_LADING_SURRENDERED = 'BILL_OF_LADING_SURRENDERED',
  FREIGHT_COLLECTION = 'FREIGHT COLLECTION',
  RELEASE_DONE = 'RELEASE_DONE',
  PIN_NUMBER = 'PIN_NUMBER',
  GATE_OUT_TERMINAL = 'GATE_OUT_TERMINAL',
  DEPOT_IN = 'DEPOT_IN',
  // booking request items
  VESSEL_SPACE = 'VESSEL_SPACE',
  EQUIPMENT_AVAILABILITY = 'EQUIPMENT_AVAILABILITY',
  QUOTATION_AGREEMENT = 'QUOTATION_AGREEMENT',
  COMMODITY_CHECK = 'COMMODITY_CHECK',
  WEIGHT_CHECK = 'WEIGHT_CHECK',
  TERMINAL_CHECK = 'TERMINAL_CHECK',
  CLOSINGS_CHECK = 'CLOSINGS_CHECK',
  FREIGHT_CHARGES = 'FREIGHT_CHARGES',
  DEPOT_LOCATION = 'DEPOT_LOCATION',
  PICKUP_AND_DELIVERY_REF = 'PICKUP_AND_DELIVERY_REF',
  SPECIAL_REMARKS = 'SPECIAL_REMARKS',
}
