import 'isomorphic-fetch';
import React, { ChangeEvent, HTMLAttributes, MutableRefObject, Ref } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, makeStyles, Paper, Popper, PopperProps, TextField, Theme } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Carrier from '../../model/Carrier';
import { FieldError } from 'react-hook-form';

const getOptionSelected = (option: Carrier, value: Carrier) => option.id === value?.id;
const getOptionLabel = (option: Carrier) => `${option.name} - (${option.id})`;

interface Props {
  label?: string;
  carriers: Carrier[] | undefined;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  value?: Carrier | null | undefined;
  onChange: (carrier: Carrier | null | undefined) => void;
  open?: boolean;
  onOpen?: (event: React.ChangeEvent<{}>) => void;
  onClose?: (event: React.ChangeEvent<{}>) => void;
  margin?: 'none' | 'dense' | 'normal';
  formError?: FieldError;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  input: {
    flexWrap: 'nowrap',
  },
}));

const CarrierInput: React.FC<Props> = ({
  label = '',
  carriers,
  inputRef,
  value,
  onChange,
  open,
  onOpen,
  onClose,
  margin,
  formError,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  const loading = open && !carriers;

  return (
    <Autocomplete
      {...rest}
      className={classes.root}
      value={value || null}
      onChange={(_: ChangeEvent<{}>, carrier: Carrier | null | undefined) => onChange(carrier)}
      autoSelect
      autoHighlight
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={carriers || []}
      loading={loading}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          error={!!formError}
          helperText={formError ? formError.message : null}
          inputRef={inputRef}
          label={label}
          margin={margin}
          disabled={disabled}
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            className: classes.input,
          }}
        />
      )}
      PopperComponent={Popup}
      PaperComponent={Papyrus}
      renderOption={(option, { inputValue }) => {
        const matches = match(getOptionLabel(option), inputValue);
        const parts = parse(getOptionLabel(option), matches);

        return (
          <div>
            {parts.map((part: { highlight: boolean; text: string }, index: number) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
};

const usePopupStyles = makeStyles((theme: Theme) => ({
  popper: {
    width: theme.breakpoints.values.md / 2,
    zIndex: 5000,
  },
}));

function Popup(props: PopperProps) {
  const { popperRef, anchorEl, open, children } = props;
  const classes = usePopupStyles();

  return (
    <Popper
      placement="bottom-start"
      popperRef={popperRef as Ref<any>}
      anchorEl={anchorEl}
      open={open}
      children={children}
      className={classes.popper}
    />
  );
}

const Papyrus: React.FC<HTMLAttributes<HTMLElement>> = ({ ...props }) => <Paper {...props} />;

export default CarrierInput;
