import palette from '../palette';

export default {
  root: {
    // marginTop: '1em',
    // marginBottom: '1em',
    '&:hover': {
      backgroundColor: palette.background.hover,
    },
  },
};
