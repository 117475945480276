import React from 'react';
import { Box, Typography } from '@material-ui/core';

const BookingsVsStockContainer: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4">Bookings vs stock</Typography>
    </Box>
  );
};

export default BookingsVsStockContainer;
