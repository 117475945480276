export default {
  '@global': {
    'input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    'input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
};
