import React from 'react';
import { Box, Typography } from '@material-ui/core';

const EquipmentBookingDetailsContainer: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4">Bookings details</Typography>
    </Box>
  );
};

export default EquipmentBookingDetailsContainer;
