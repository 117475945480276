import React from 'react';
import { Box, Typography } from '@material-ui/core';

const OverviewsContainer: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4">Overviews</Typography>
    </Box>
  );
};

export default OverviewsContainer;
