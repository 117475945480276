// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/firebase-functions';

const developmentConfig = {
  apiKey: 'AIzaSyCGqNPUnvvO9sGCfYeC3IdeAw_jMUpJ5NQ',
  authDomain: 'brunoni-allmarine.firebaseapp.com',
  databaseURL: 'https://brunoni-allmarine.firebaseio.com',
  projectId: 'brunoni-allmarine',
  storageBucket: 'brunoni-allmarine.appspot.com',
  messagingSenderId: '954272298910',
  appId: '1:954272298910:web:e52f968551a80bbadbc88f',
};

const productionConfig = {
  brunoni: {
    apiKey: 'AIzaSyBuI3STGixq5uWBcCzrUuMGfbifkDOZYN0',
    authDomain: 'brunoni.firebaseapp.com',
    databaseURL: 'https://brunoni.firebaseio.com',
    projectId: 'brunoni',
    storageBucket: 'brunoni.appspot.com',
    messagingSenderId: '154343276895',
    appId: '1:154343276895:web:a30fe5aced02b0373af6ea',
  },
  allmarine: {
    apiKey: 'AIzaSyBfF4OAXLNZsNgER4bKnZhABWIZron9T4o',
    authDomain: 'allmarine.firebaseapp.com',
    databaseURL: 'https://allmarine.firebaseio.com',
    projectId: 'allmarine',
    storageBucket: 'allmarine.appspot.com',
    messagingSenderId: '707393375364',
    appId: '1:707393375364:web:283aa80ae0e376749f86d1',
  },
};

const firebaseConfig =
  process.env.REACT_APP_ENV === 'stage'
    ? developmentConfig // TODO this means that stage.brunoni.ch will use brunoni-allmarine DB. Good for now, revisit later :)
    : process.env.NODE_ENV === 'production'
    ? productionConfig[process.env.REACT_APP_BRAND as 'brunoni' | 'allmarine']
    : process.env.REACT_APP_API_URL === 'https://stage-dot-brunoni.appspot.com'
    ? productionConfig.brunoni
    : process.env.REACT_APP_API_URL === 'https://stage-dot-allmarine.appspot.com'
    ? productionConfig.allmarine
    : developmentConfig;

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

// firebase
//   .firestore()
//   .clearPersistence()
//   .catch(error => {
//     console.error('Could not clear persistence:', error.code);
//   });

// firebase
//   .firestore()
//   .enablePersistence({
//     synchronizeTabs: true,
//   })
//   .then(() => {
//     console.log('Enabled offline sync');
//   })
//   .catch(function(err) {
//     console.warn('Failed to enable firestore persistence', err);
//   });

export default firebase;
